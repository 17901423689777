<template>
	<v-container fluid style="max-width: 1500px">
		<v-fade-transition hide-on-leave>
			<template v-if="loadingEvent">
				<v-skeleton-loader key="skeleton" type="list-item-avatar" min-width="1500" height="120" class="rounded-xl" />
			</template>
			<template v-else>
				<div class="d-flex justify-space-between flex-wrap">
					<v-row key="eventFirstRow">
						<v-col cols="12" md="2" sm="3" xs="5">
							<EventLogo :loading-parent="loadingEvent" />
						</v-col>
						<v-col cols="12" md="10" sm="9" xs="7">
							<EventInfo :loading-parent="loadingEvent" />
						</v-col>
					</v-row>
					<div class="d-flex justify-space-around flex-wrap" :class="{ 'flex-column': !$vuetify.breakpoint.smAndDown }">
						<EventAttendWebsiteButton :loading-parent="loadingEvent" />
					</div>
				</div>
			</template>
		</v-fade-transition>

		<v-divider class="my-2" />

		<v-chip-group v-model="chipSelected" column color="sidebarBackground" active-class="sidebarBackground" class="my-3">
			<v-chip value="EventFeed" :disabled="chipSelected == 'EventFeed'">
				<v-btn text :to="{ name: 'EventFeed', params: { id: event ? event.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-newspaper</v-icon>
					{{ $t('nav.newsfeed') }}
				</v-btn>
			</v-chip>
			<v-chip value="EventDescription" :disabled="chipSelected == 'EventDescription'">
				<v-btn text :to="{ name: 'EventDescription', params: { id: event ? event.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-text</v-icon>
					{{ $t('courses.description') }}
				</v-btn>
			</v-chip>
			<v-chip value="EventActivity" :disabled="chipSelected == 'EventActivity'">
				<v-btn text :to="{ name: 'EventActivity', params: { id: event ? event.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-rss</v-icon>
					{{ $t('offers.activity') }}
				</v-btn>
			</v-chip>
			<v-chip value="EventAttendances" :disabled="chipSelected == 'EventAttendances'">
				<v-btn text :to="{ name: 'EventAttendances', params: { id: event ? event.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-account-group</v-icon>
					{{ $t('applications.applications') }}
				</v-btn>
			</v-chip>
		</v-chip-group>

		<v-divider class="my-2" />

		<router-view />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.title ? `${this.$t('events.event')} - ${this.title}` : this.$t('events.event')
		}
	},
	data() {
		return {
			title: '',
			chipSelected: 'EventFeed'
		}
	},
	components: {
		EventLogo: () => import('@/components/events/EventLogo.vue'),
		EventInfo: () => import('@/components/events/EventInfo.vue'),
		EventAttendWebsiteButton: () => import('@/components/events/EventAttendWebsiteButton.vue')
	},
	watch: {
		'$route.params.id'(id) {
			this.setLoadingEvent(true)
			this.fetchEvent(id)
				.then(({ success }) => {
					if (success) this.title = this.event.title
				})
				.then(() => {
					this.setLoadingEvent(false)
				})
		}
	},
	computed: {
		...mapGetters({
			event: 'events/event',
			loadingEvent: 'events/loading'
		})
	},
	created() {
		this.chipSelected = this.$route.name
		this.setLoadingEvent(true)
		this.fetchEvent(this.$route.params.id)
			.then(({ success }) => {
				if (success) this.title = this.event.title
			})
			.then(() => {
				this.setLoadingEvent(false)
			})
	},
	methods: {
		...mapActions('events', ['setLoadingEvent', 'fetchEvent'])
	}
}
</script>

<style scoped>
.v-chip--disabled {
	opacity: 1;
}
</style>
